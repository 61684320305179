export const TAB_ID_INDEXED_RECORDS = 'indexed-records';
export const TAB_ID_FULL_TEXT = 'full-text';
export const TAB_ID_SOURCES = 'sources';
export const TAB_ID_AI_SEARCH = 'ai';

export const PERSON_FACT_FIELDS = [
  'fact_type',
  'fact_category',
  'value',
  'year',
  'approx',
  'place_id',
  'place_display_text',
  'cemetery_id',
];
export const VALIDATE_FACT_FIELD_SET = ['value', 'year', 'place_id', 'place_display_text', 'cemetery_id'];
export const FACT_CATEGORY_IMMIGRATION = 'Immigration';
export const RELATIVES_OPTIONS = [
  {id: 'spouse', label: 'Spouse'},
  {id: 'child', label: 'Child'},
  {id: 'parent', label: 'Parent'},
  {id: 'sibling', label: 'Sibling'},
];
export const PERSON_RELATIVE_FIELDS = ['first_name', 'surname', 'relation_type'];

export const SEARCH_TAB_BY_DEFAULT_FORM = {
  indexed: TAB_ID_INDEXED_RECORDS,
  full_text: TAB_ID_FULL_TEXT,
  sources: TAB_ID_SOURCES,
};

export const HAS_IMAGES_OPTIONS = {true: 'Images Available', false: 'No Images Available'};
export const HAS_OCR_OPTIONS = {true: 'Full Text Available', false: 'Full Text Not Available'};
