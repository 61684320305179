import network from '@common/network/network';
import moment from 'moment';

export default {
  fetchFamilyTreePersonAction(context, id) {
    context.commit('setFamilyTreePersonLoadingState', true);

    return new Promise((resolve, reject) => {
      network.familyTreePerson
        .details({id})
        .then(response => {
          const treePerson = context.getters.familyTreePersonsByIdState[id];
          context.commit('setFamilyTreePersonState', treePerson || {});
          context.commit('mutateFamilyTreePersonState', response);
          context.commit('mutateFamilyTreePersonState', {
            full_name:
              context.getters.familyTreePersonPrimaryFullnameEnState ||
              context.getters.familyTreePersonPrimaryFullnameCnState,
          });
          resolve(response);
        })
        .catch(error => {
          context.commit('setFamilyTreePersonState', null);
          reject(error);
        })
        .finally(() => {
          context.commit('setFamilyTreePersonLoadingState', false);
        });
    });
  },
  fetchFamilyTreePersonExtraDataAction(context, id) {
    return new Promise((resolve, reject) => {
      network.familyTreePerson
        .getExtraData({id})
        .then(response => {
          context.commit('mutateFamilyTreePersonsByIdState', {id: id, ...response});
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  },
  fetchFamilyTreePersonRelativesAction(context, id) {
    context.commit('setFamilyTreePersonRelativesLoadingState', true);

    return new Promise((resolve, reject) => {
      network.familyTreePerson
        .relatives({id})
        .then(response => {
          context.commit('setFamilyTreePersonRelativesState', {[id]: response});
          context.commit('setFamilyTreePreferencesKeyState', response.preferences);
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setFamilyTreePersonRelativesLoadingState', false);
        });
    });
  },
  fetchFamilyTreePersonRelativesInBackgroundAction(context, id) {
    return new Promise((resolve, reject) => {
      network.familyTreePerson
        .relatives({id})
        .then(response => {
          context.commit('setFamilyTreePersonRelativesState', {[id]: response});
          resolve(response);
        })
        .catch(reject);
    });
  },
  fetchFamilyTreePersonOptionsAction(context) {
    context.commit('setFamilyTreeOptionsLoadingState', true);

    return network.familyTreePerson
      .options()
      .then(response => {
        response.genders = {...response.genders, null: 'Unknown'};
        context.commit('setFamilyTreeOptionsState', response);
      })
      .catch(error => {})
      .finally(() => {
        context.commit('setFamilyTreeOptionsLoadingState', false);
      });
  },
  updateFamilyTreePersonAction(context, params) {
    context.commit('setFamilyTreePersonUpdateLoadingState', true);

    return new Promise((resolve, reject) => {
      network.familyTreePerson
        .update(params)
        .then(response => {
          context.commit('mutateFamilyTreePersonState', response);
          const data = {
            full_name:
              context.getters.familyTreePersonPrimaryFullnameEnState ||
              context.getters.familyTreePersonPrimaryFullnameCnState,
            gender: params.gender,
            id: params.id,
            object_id: response.object_id,
            birth_date: params.birth_date,
            birth_location: params.birth_location,
            death_date: params.death_date,
            death_location: params.death_location,
            is_deceased: params.is_deceased,
            first_names: params.first_names,
            surnames: params.surnames,
            generation_number: response.generation_number,
            notes_display: response.notes_display,
          };
          context.commit('mutateFamilyTreePersonsByIdState', data);
          context.commit('mutatePersonFromFamilyTreePersonRelativesState', data);
          context.commit('mutatePersonFromFamilyTreeLineageState', data);
          resolve(response);
        })
        .catch(error => {
          context.commit(
            'setFamilyTreePersonUpdateErrorsState',
            error && error.response && error.response.data && error.response.data.person
          );
          reject(error);
        })
        .finally(() => {
          context.commit('setFamilyTreePersonUpdateLoadingState', false);
        });
    });
  },
  updateFamilyTreePersonFactsAction(context, {personId, facts}) {
    context.commit('setFamilyTreePersonFactsUpdateLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTreePerson
        .updateFacts({id: personId, facts})
        .then(response => {
          context.commit('mutateFamilyTreePersonState', {facts: response || []});
          resolve();
        })
        .catch(reject)
        .finally(() => {
          context.commit('setFamilyTreePersonFactsUpdateLoadingState', false);
        });
    });
  },
  importRelativesToFamilyTreePerson(context, {id, new_relatives, from_hint}) {
    return new Promise((resolve, reject) => {
      network.familyTreePerson
        .importRelatives({id, new_relatives, from_hint})
        .then(response => {
          context.commit('setFamilyTreePersonsDrawnState', null);
          context.commit('setFamilyTreeLinesDrawnState', null);
          context.commit('setFamilyTreePersonState', null);
          resolve();
        })
        .catch(reject);
    });
  },
  deleteFamilyTreePersonAction(context, personId) {
    context.commit('setFamilyTreePersonDeleteLoadingState', true);
    let personObject = context.getters.familyTreePersonsByIdState[personId];

    let focusOnIdAfter = -1;
    const relatives = personObject ? personObject.relatives : {};
    if (relatives.spouses && relatives.spouses[0]) {
      focusOnIdAfter = relatives.spouses[0].object_id;
    } else if (relatives.siblings && relatives.siblings[0]) {
      focusOnIdAfter = relatives.siblings[0].object_id;
    } else if (relatives.parents && relatives.parents[0]) {
      focusOnIdAfter = relatives.parents[0].object_id;
    } else if (relatives.children && relatives.children[0]) {
      focusOnIdAfter = relatives.children[0].object_id;
    }

    return new Promise((resolve, reject) => {
      network.familyTreePerson
        .delete({id: personId})
        .then(response => {
          if (context.getters.familyTreePersonState && context.getters.familyTreePersonState.object_id === personId) {
            context.commit('setFamilyTreePersonState', null);
          }
          if (context.getters.familyTreeDetailsIdState) {
            context
              .dispatch('fetchFamilyTreeMapDiffAction', {
                id: context.getters.familyTreeDetailsIdState,
                start_person_id: context.getters.familyTreeStartPersonIdState,
                map_hash: context.getters.familyTreeMapImageHashState,
              })
              .then(() => {
                context.commit('setFamilyTreeFocusPersonIdState', focusOnIdAfter);
                resolve();
              });
          } else {
            resolve();
          }
          context.commit('setFamilyTreeLineageState', null);
          context.commit('deleteFromFamilyTreePersonsSearchListState', personId);
        })
        .catch(error => {
          context.commit('setFamilyTreePersonDeleteErrorsState', error.response.data.error);
          reject(error.response.data);
        })
        .finally(() => {
          context.commit('setFamilyTreePersonDeleteLoadingState', false);
        });
    });
  },
  createFamilyTreePersonAction(context, personData) {
    context.commit('setFamilyTreePersonCreateLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTreePerson
        .create(personData)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          const data = error && error.response && error.response.data;
          context.commit('setFamilyTreePersonCreateErrorsState', data ? data.error : '');
          reject(data);
        })
        .finally(() => {
          context.commit('setFamilyTreePersonCreateLoadingState', false);
        });
    });
  },
  fetchFamilyTreePersonFilesAction(context, {treeId, personId, page}) {
    context.commit('setFamilyTreePersonFilesLoadingState', true);
    return new Promise((resolve, reject) => {
      const limit = 40;
      const offset = limit * (page - 1);
      network.familyTreeLibrary
        .getAssets({id: treeId, order_by: '-created_at', limit, offset, persons: personId})
        .then(response => {
          context.commit('setFamilyTreePersonFilesState', response.objects);
          context.commit('setFamilyTreePersonFilesMetaState', response.meta);
          resolve();
        })
        .catch(err => {
          context.commit('setFamilyTreePersonFilesState', []);
          context.commit('setFamilyTreePersonFilesMetaState', {});
          reject();
        })
        .finally(() => {
          context.commit('setFamilyTreePersonFilesLoadingState', false);
        });
    });
  },
  uploadFamilyTreePersonFileAction(context, {familyTreeId, personId, file}) {
    const id = moment().unix() + '__' + file.lastModified + '__' + file.name;
    const photo = {
      _id: id,
      progress: '0',
      error: false,
      uploading: true,
      attachment: '',
      filename: file.name,
      uploadingPreviewSrc: /image/g.test(file.type) ? URL.createObjectURL(new Blob([file])) : '',
    };
    const tagsData = {persons: [{pk: personId}]};
    context.commit('addToFamilyTreePersonFilesState', photo);
    return new Promise((resolve, reject) => {
      network.familyTreeLibrary
        .uploadFile({id: familyTreeId, attachment: file, tags_data: JSON.stringify(tagsData)}, progressRes => {
          photo.progress = ((progressRes.loaded / progressRes.total) * 100).toFixed(2);
        })
        .then(response => {
          context.commit('onUploadFamilyTreePersonFileState', {...response, _id: id});
          resolve(response);
        })
        .catch(error => {
          photo.error = true;
          reject(error);
        });
    });
  },
  fetchFamilyTreePersonPhotosAction(context, personId) {
    context.commit('setFamilyTreePersonPhotosLoadingState', true);

    return new Promise((resolve, reject) => {
      network.familyTreePerson
        .getPhotos({id: personId})
        .then(response => {
          const photos = response.objects ? response.objects : response;
          context.commit('setFamilyTreePersonPhotosState', {id: personId, photos: photos});
          resolve(response);
        })
        .finally(() => {
          context.commit('setFamilyTreePersonPhotosLoadingState', false);
        });
    });
  },
  uploadFamilyTreePersonPhotoAction(context, {personId, file}) {
    const id = moment().unix() + '__' + file.lastModified + '__' + file.name;
    const photo = {
      _id: id,
      progress: '0',
      error: false,
      uploading: true,
      attachment: '',
      filename: file.name,
      uploadingPreviewSrc: /image/g.test(file.type) ? URL.createObjectURL(new Blob([file])) : '',
    };
    context.commit('addToFamilyTreePersonPhotoState', {id: personId, photo});
    return new Promise((resolve, reject) => {
      network.familyTreePerson
        .uploadPhoto({id: personId, attachment: file}, progressRes => {
          photo.progress = ((progressRes.loaded / progressRes.total) * 100).toFixed(2);
        })
        .then(response => {
          context.commit('onUploadFamilyTreePersonPhotoState', {id: personId, photo: {...response, _id: id}});
          resolve(response);
        })
        .catch(error => {
          photo.error = true;
          reject(error);
        });
    });
  },
  setFamilyTreePersonProfilePictureAction(context, {personId, assetId, file}) {
    context.commit('setFamilyTreePersonProfilePictureLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTreePerson
        .setProfilePicture({id: personId, attachment: file, asset_id: assetId})
        .then(response => {
          context.commit('mutateFamilyTreePersonState', {profile_picture: response});
          context.commit('mutatePersonFromFamilyTreeLineageState', {object_id: personId, profile_picture: response});
          context.commit('mutateFamilyTreePersonsByIdState', {id: personId, profile_picture: response});
          resolve();
        })
        .finally(() => {
          context.commit('setFamilyTreePersonProfilePictureLoadingState', false);
          reject();
        });
    });
  },
  deletePersonAssetAction(context, {personId, assetId}) {
    context.commit('setFamilyTreePersonDeleteAssetLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTreeLibrary
        .deleteAsset({id: assetId})
        .then(() => {
          context.commit('removeFromFamilyTreePeronPhotosState', {personId, assetId});
          context.commit('removeFromFamilyTreePeronFilesState', assetId);
          context.commit('removeItemByIdFromGalleryState', assetId);
          const person = context.getters.familyTreePersonState || context.getters.familyTreePersonsByIdState[personId];
          if (person && person.profile_picture && person.profile_picture.asset_id == assetId) {
            context.commit('mutateFamilyTreePersonState', {profile_picture: {}});
            context.commit('mutatePersonFromFamilyTreeLineageState', {object_id: personId, profile_picture: {}});
            context.commit('mutateFamilyTreePersonsByIdState', {id: personId, profile_picture: {}});
          }
          resolve();
        })
        .finally(() => {
          context.commit('setFamilyTreePersonDeleteAssetLoadingState', false);
        });
    });
  },
  fetchFamilyTreePersonsFullNamesByIdsAction(context, ids) {
    return network.familyTreePerson.getFullNamesByIds({ids});
  },
  fetchUserPersonSurnames(context) {
    context.commit('setUserPersonSurnamesLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTreePerson
        .getMySurnames()
        .then(response => {
          context.commit('setUserPersonSurnamesState', response);
          resolve(response);
        })
        .finally(() => {
          context.commit('setUserPersonSurnamesLoadingState', false);
        });
    });
  },
  familyTreePersonAcceptDataAction(context, {id, data}) {
    context.commit('setPersonAcceptDataLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTreePerson
        .acceptData({id, data})
        .then(resolve)
        .catch(reject)
        .finally(() => {
          context.commit('setPersonAcceptDataLoadingState', false);
        });
    });
  },
};
